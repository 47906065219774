import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import type { PaymentMethodType } from "../../types/PaymentMethodType"

import InformationWrapper from "../InformationWrapper"
import InformationRow from "../InformationRow"
import PaymentMethod from "../PaymentMethod"

import { ROUTES, formatRichTextLink } from "@lesmills/gatsby-theme-common"

type Props = {|
  paymentDetailSection: Object,
  sectionStyles?: Object,
  paymentMethod: PaymentMethodType,
  lang: string,
  isResellerCustomerUser?: Boolean,
|}

const PaymentDetailSection = ({
  paymentDetailSection = {},
  sectionStyles = {},
  paymentMethod,
  lang,
  isResellerCustomerUser = false,
}: Props) => {
  const { section_title = {}, update_url = {} } =
    paymentDetailSection.primary || {}
  const [paymentMethodLabel = { name: {} }] = paymentDetailSection.items || []
  return (
    <InformationWrapper
      title={section_title.text}
      // AB2B-695: hide update payment with Paypal pay user
      updateLink={
        // Reseller user does not have payment details to update
        !isResellerCustomerUser
          ? {
              label: update_url.text,
              url: ROUTES(lang).UPDATE_PAYMENT_DETAILS,
            }
          : {}
      }
      externalLink={
        process.env.GATSBY_RT_05_04_2022_CHANGE_SUBSCRIPTION === "true"
          ? process.env.GATSBY_UPDATE_PAYMENT_LINK_NEW ||
            `${process.env.GATSBY_GETTING_STARTED_URL}update-payment-details/`
          : null
      }
      classNames={sectionStyles}
    >
      {!isResellerCustomerUser && (
        <InformationRow
          label={paymentMethodLabel.name.text}
          value={<PaymentMethod paymentMethod={paymentMethod} />}
          testId="payment-method"
        />
      )}
      {
        <div className="flex items-center justify-end border-t border-gray-35 pt-40 mt-30">
          {paymentMethodLabel.description &&
            formatRichTextLink(
              paymentMethodLabel.description.raw,
              "text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800"
            )}
        </div>
      }
    </InformationWrapper>
  )
}

export default memo(PaymentDetailSection, (prevProps, nextProps) => {
  return isEqual(prevProps.paymentMethod, nextProps.paymentMethod)
})
